<template>
    <div style="min-height: 80vh">
        <el-row>
            <el-col :span="23">
                <el-card class="box-card" shadow="never">
                    <div slot="header">
                        <i class="iconfont el-icon-s-order c-base mr-2"></i>
                        <span class="fwb">办理中订单</span>
                    </div>
                    <el-tabs v-model="editableTabsValue" @tab-click="getOrderList">
                        <el-tab-pane
                                :key="item.name"
                                v-for="item in editableTabs"
                                :label="item.title"
                                :name="item.name">
                            <div>
                                <el-table
                                        :data="orderList"
                                        border
                                        v-loading="dataListLoading"
                                        style="width: 100%;">
                                    <el-table-column
                                            type="index"
                                            header-align="center"
                                            align="center"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            prop="orderNo"
                                            header-align="center"
                                            align="center"
                                            label="订单编号">
                                    </el-table-column>
                                    <el-table-column
                                            prop="serviceName"
                                            header-align="center"
                                            align="center"
                                            label="需求产品">
                                    </el-table-column>
                                    <el-table-column
                                            prop="orderStatus"
                                            header-align="center"
                                            align="center"
                                            label="订单状态">
                                        <template slot-scope="scope">
                                            <el-tag :type="tagType[scope.row.status]">
                                                {{ orderStatusDict[scope.row.orderStatus] }}
                                            </el-tag>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="payStatus"
                                            header-align="center"
                                            align="center"
                                            label="付款状态">
                                        <template slot-scope="scope">
                                            <el-tag :type="payTagType[scope.row.payStatus]">
                                                {{ payStatusDict[scope.row.payStatus] }}
                                            </el-tag>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="commentStatus"
                                            header-align="center"
                                            align="center"
                                            min-width="110"
                                            label="评价状态">
                                        <template slot-scope="scope">
                                            <el-tag :type="commentType[scope.row.commentStatus]">
                                                {{ commentStatusDict[scope.row.commentStatus] }}
                                            </el-tag>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="contacts"
                                            header-align="center"
                                            align="center"
                                            label="联系人">
                                    </el-table-column>
                                    <el-table-column
                                            prop="mobile"
                                            header-align="center"
                                            align="center"
                                            show-overflow-tooltip
                                            label="联系电话">
                                    </el-table-column>
                                    <el-table-column
                                            prop="createTime"
                                            header-align="center"
                                            align="center"
                                            label="成交时间">
                                    </el-table-column>
                                    <el-table-column
                                            prop="totalSum"
                                            header-align="center"
                                            align="center"
                                            label="金额(元)">
                                    </el-table-column>
                                    <el-table-column
                                            fixed="right"
                                            header-align="center"
                                            align="center"
                                            width="150"
                                            label="操作">
                                        <template slot-scope="scope">
                                            <el-button v-if="scope.row.payTotalSum !== scope.row.totalSum" type="text"
                                                       size="small"
                                                       @click="openPayment(scope.row)">订单支付
                                            </el-button>
                                            <!--          <el-button v-if="scope.row.payStatus === 2" type="text" size="small" @click="orderRefund(scope.row)">申请退款</el-button>-->
                                            <el-button type="text" size="small" @click="changePage(scope.row)">
                                                查看详情
                                            </el-button>
                                            <!-- <el-button type="text" size="small" @click="deleteHandle(scope.row.id)"
                                                       :disabled="scope.row.payStatus != 1">
                                              删除订单
                                            </el-button> -->
                                            <!--          v-if="scope.row.orderStatus===3"-->
                                            <!--            <el-button :disabled="scope.row.orderStatus!==3" type="text" size="small" @click="evaluteClick(scope.row)">评价</el-button>-->
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination
                                        class="tac mt-3"
                                        background
                                        @size-change="sizeChangeHandle"
                                        @current-change="currentChangeHandle"
                                        :current-page="pageIndex"
                                        :page-sizes="[10, 20, 50, 100]"
                                        :page-size="pageSize"
                                        :total="totalPage"
                                        layout="total, sizes, prev, pager, next, jumper">
                                </el-pagination>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
        </el-row>
        <el-row class="mt-3" :gutter="15">
            <el-col :span="18">
                <el-row :gutter="15">
                    <el-col :span="24">
                        <el-card class="box-card" shadow="never" :body-style="cardBodyStyle">
                            <div slot="header">
                                <i class="iconfont el-icon-s-order c-base mr-2"></i>
                                <span class="fwb">最近浏览</span>
                            </div>
                            <div class="service-wrapper p-2">
                                <div class="ser-li uf uf-pj uf-ac wi100" v-for="item in viewList" @click="goDetail(item.serviceId)" :key="item.id">
                                    <div class="uf uf-ac">
                                        <div class="fwb f18 mr-5">{{ item.serviceName }}</div>
                                        <div class="">
                                            <span class="op-07">所属行业：</span>
                                            <span>{{ item.itemName }}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <el-button type="text">详情 <i class="el-icon-right"></i></el-button>
                                    </div>
                                </div>
<!--                                <service-card v-for="item in viewList" :service="item"-->
<!--                                              @showDetail="goDetail(item.serviceId)" :key="item.id"></service-card>-->
                            </div>
                            <!--              <el-row :gutter="10" class="ad-list">-->
                            <!--                <el-col :sm="3" :md="4" :lg="6" style="height: 280px" v-for="(item, index) in viewList" :key="index">-->
                            <!--                  <div @click="goDetail(item.serviceId)" style="margin-bottom: 20px;background: #eff2f7;">-->
                            <!--                    <div class="pic-box">-->
                            <!--                      <el-image class="img-wrapper"-->
                            <!--                                :src="$cons.SERVER_PATH + item.logoUrl" fit="fill">-->
                            <!--                        <div slot="error">-->
                            <!--                          <div class="item-error">{{ item.serviceName }}</div>-->
                            <!--                        </div>-->
                            <!--                      </el-image>-->
                            <!--                    </div>-->
                            <!--                    <div class="f18 price-wrapper">-->
                            <!--                      <span class="f20" v-if="item.minPrice !== -1">￥{{ (item.minPrice || 0).toFixed(2) }}</span>-->
                            <!--                      <span v-else class="f20">面议</span>-->
                            <!--                    </div>-->
                            <!--                    <div>-->
                            <!--                      <span>{{-->
                            <!--                          item.serviceName && item.serviceName.length > 11 ? item.serviceName.substr(0, 10) + '...' : item.serviceName-->
                            <!--                        }}</span>-->
                            <!--                    </div>-->
                            <!--                    <div class="f12 pb-1">-->
                            <!--                      <span class="op-07" style="margin-bottom: 10px">服务分类：{{ item.itemName }}</span>-->
                            <!--                    </div>-->
                            <!--                  </div>-->
                            <!--                </el-col>-->
                            <!--              </el-row>-->
                        </el-card>
                    </el-col>
                </el-row>
                <!--            <el-row style="margin-top:20px">-->
                <!--              <el-card class="box-card" shadow="never">-->
                <!--                <div slot="header" class="uf uf-ac">-->
                <!--                <i class="iconfont el-icon-menu c-base mr-2"></i>-->
                <!--                <span class="fwb">我的应用</span>-->
                <!--              </div>-->
                <!--              <el-row :gutter="20">-->
                <!--                <el-col :span="6">-->
                <!--                  <el-link href="http://model.smartquality.cn/">-->
                <!--                    <img src="@/assets/img/cpzhljc.png" class="img-box" />-->
                <!--                  </el-link>-->
                <!--                </el-col>-->
                <!--                <el-col :span="6">-->
                <!--                  <img src="@/assets/img/cpzhljc.png" class="img-box"/>-->
                <!--                </el-col>-->
                <!--                <el-col :span="6">-->
                <!--                  <img src="@/assets/img/cpzhljc.png" class="img-box"/>-->
                <!--                </el-col>-->
                <!--                <el-col :span="6">-->
                <!--                  <img src="@/assets/img/cpzhljc.png" class="img-box"/>-->
                <!--                </el-col>-->
                <!--              </el-row>-->
                <!--            </el-card>-->
                <!--            </el-row>-->
            </el-col>
            <el-col :span="5">
                <!--            <el-card class="box-card" shadow="never" style="margin-top: -1px">-->
                <!--              <div slot="header">-->
                <!--                <i class="iconfont icon-qiye c-base mr-2"></i>-->
                <!--                <span class="fwb">产品推荐</span>-->
                <!--              </div>-->
                <!--                <div class="ad-list" v-for="(item, index) in recommendList" :key="index">-->
                <!--                  <div class="pic-box" @click ="goDetail(item.id)">-->
                <!--                    <img style="width:100%"-->
                <!--                      :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')"/>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--            </el-card>-->
                <el-card class="box-card" shadow="never" style="height: 100%;">
                    <div slot="header">
                        <i class="iconfont el-icon-message-solid c-base mr-2"></i>
                        <span class="fwb">最新消息</span>
                    </div>
                    <div style="margin-top: -15px">
                        <p v-for="(item ,i) in latestNewList" :key="i">
                            <el-badge :is-dot="item.msgStatus == 0 ? true : false" class="item">
                <span class="text-shenglue" :title="item.msgObj"><i
                        class="el-icon-time margin-r-5"></i>{{ item.createTime }}：{{ item.msgObj }}</span>
                            </el-badge>
                        </p>
                        <span class="s" @click="goRemind()">>>查看全部</span>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <!-- 支付弹窗 -->
        <pay v-if="payVisible" ref="pay" @payCloseCallback="payCloseCallback" @paymentSuccessful="paymentSuccessful"/>
    </div>
</template>

<script>
import moment from 'moment'
import Pay from '../../../service/pay'

export default {
    components: {
        Pay
    },
    name: 'home',
    data() {
        return {
            latestNewList: [],
            payVisible: false,
            loading: false,
            dataListLoading: false,
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            viewList: [],
            orderList: [],
            dateRange: [],
            orderStatusDict: {},
            payStatusDict: {},
            commentStatusDict: {},
            editableTabsValue: '1',
            tagType: {1: '', 2: '', 3: 'success', 8: 'danger'},
            payTagType: {1: '', 2: 'success', 3: 'danger', 4: 'info', 5: 'warning'},
            commentType: {1: 'danger', 2: 'success', 3: 'success'},
            editableTabs: [{
                title: '待支付',
                name: '1'
            },
                {
                    title: '办理中',
                    name: '2'
                }],
            cardBodyStyle: {
                padding: '0px',
                backgroundColor: '#FFFFFF'
            }
        }
    },
    created() {
        this.$getDictMap('ORDER_STATUS,PAY_STATUS,ORDER_COMMENT_STATUS', (dict) => {
            this.orderStatusDict = dict.ORDER_STATUS
            this.payStatusDict = dict.PAY_STATUS
            this.commentStatusDict = dict.ORDER_COMMENT_STATUS
        })
        this.recommendService()
        // this.orderCount()
        // 初始化查询时间范围
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
        this.dateRange = [start, end]
        this.getNewsList()
        this.getViewList()
        this.getOrderList()
    },
    methods: {
        // 获取数据列表
        getNewsList() {
            this.dataListLoading = true
            let [beginDate, endDate] = this.dateRange || []
            this.$http({
                url: this.$http.adornUrl(this.$api.MESSAGE.LIST),
                method: 'get',
                params: this.$http.adornParams({
                    'page': this.pageIndex,
                    'limit': this.pageSize,
                    'beginDate': beginDate ? moment(beginDate).format('YYYY-MM-DD') : null,
                    'endDate': endDate ? moment(endDate).format('YYYY-MM-DD') : null
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.latestNewList = data.page.list
                } else {
                    this.dataList = []
                }
                this.dataListLoading = false
            })
        },
        //猜你喜欢
        recommendService() {
            this.$http({
                url: this.$http.adornUrl(this.$api.PUB.RECOMMEND_LIST),
                method: 'post',
                params: this.$http.adornParams({
                    'page': 1,
                    'limit': 3
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.recommendList = data.page.list
                } else {
                    this.dataList = []
                }
            })
        },
        goRemind() {
            this.$router.push('remind')
        },
        //跳转产品详情
        goDetail(id) {
            this.$router.push({name: 'service', query: {id, isProvider: false}})
        },
        // 获取数据列表
        getViewList() {
            this.dataListLoading = true
            this.$http({
                url: this.$http.adornUrl(this.$api.VIEW.LIST),
                method: 'get',
                params: this.$http.adornParams({
                    'page': this.pageIndex,
                    'limit': 12
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.viewList = data.page.list
                } else {
                    this.viewList = []
                }
                this.dataListLoading = false
            })
        },
        // neu () {
        //   this.$http({
        //     url: this.$http.adornUrl(this.$api.ORDER.NEU_ORDER),
        //     method: 'post',
        //     data: this.$http.adornData({})
        //   }).then(({data}) => {
        //     if (data && data.code === 0) {
        //     } else {
        //       this.$message.error(data.msg)
        //     }
        //   })
        // },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getOrderList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getOrderList()
        },
        // 获取数据列表
        getOrderList(tab, event) {
            this.dataListLoading = true
            this.$http({
                url: this.$http.adornUrl(this.$api.ORDER.LIST),
                method: 'get',
                params: this.$http.adornParams({
                    'page': this.pageIndex,
                    'limit': this.pageSize,
                    'orderStatus': this.editableTabsValue || '',
                    'beginDate': '',
                    'endDate': ''
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.orderList = data.page.list
                    this.totalPage = data.page.totalCount
                } else {
                    this.orderList = []
                    this.totalPage = 0
                }
                this.dataListLoading = false
            })
        },
        payCloseCallback(refresh) {
            if (refresh) {
                this.getOrderList()
            }
        },
        // 支付
        openPayment(info) {
            this.payVisible = true
            this.$nextTick(() => {
                this.$refs['pay'].init(info)
            })

            // 线下支付，暂时只做提示使用
            // this.$alert('请您按照客服人员提供的银行账户信息进行付款。', '提示', {
            //   confirmButtonText: '确定',
            // })
        },
        //跳转支付成功页面
        paymentSuccessful(orderNo, totalSum) {
            this.payVisible = false
            //延迟跳转
            this.$router.push({
                name: 'pay-successful',
                params: {
                    orderNo: orderNo,
                    totalSum: totalSum,
                    serviceName: this.dataForm.serviceName
                }
            })
        },
        changePage(row) {
            this.$router.push({name: 'my-order', query: {row, isProvider: false}})
        }
    }
}

</script>

<style scoped>
.home-center .header {
    font-size: 24px;
    color: #222;
    font-weight: bold;
    text-align: center;
    /* padding: 24px 0; */
}
.ser-li {
    padding: 20px;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .3s ease;
}
.ser-li:hover {
    box-shadow: 0 0 15px rgba(0,0,0,.2);
}
.ad-list {
    /* position: relative; */
    margin-top: 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    background: #ffffff;
    /* line-height: 20px; */
    /* border: 1px solid #DCDFE6; */
    cursor: pointer;
}

.box-card {
    width: 100%;
}

.pic-box {
    width: 100%;
    height: 100%;
    background: #ffffff;
}

.s {
    color: #0ea2fd;
    cursor: pointer;
}

.s:hover {
    color: #3987dd;
    text-decoration: underline;
    opacity: 1;
    /*border: 1px solid #409EFF;*/
    transform: translate3d(0, -2px, 0);
}

.img-box {
    height: 180px;
    margin: 0 auto;
    background: #ffffff;
    line-height: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, .15);
    cursor: pointer;
}

.img-box:hover {
    /*border: 1px solid #409EFF;*/
    box-shadow: 0 8px 15px rgba(0, 0, 0, .15);
}

.text-shenglue {
    width: 260px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.text-shenglue:hover {
    color: #3987dd;
    text-decoration: underline;
    opacity: 1;
    /*border: 1px solid #409EFF;*/
    transform: translate3d(0, -2px, 0);
}

.img-wrapper {
    width: 100%;
    height: 192px;
}

.item-error {
    width: 100%;
    height: 192px;
    background-image: url("../../../../assets/img/product/default_3.png");
    background-size: cover;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.2rem;
    padding: 40px 10px;
    color: #FFFFFF;
    cursor: default;
}

.price-wrapper {
    padding: 0px 5px 5px 5px;
    color: #fe3b25;
}

.service-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
}
</style>
